import React from 'react';
import './TitleBig.scss';

const TitleBig = ({ title, titleYellow, width, maxWidth }) => {
	return (
		<h1 style={{ width: width, maxWidth: maxWidth }} className='title-big'>
			{title} {titleYellow && <span>{titleYellow}</span>}
		</h1>
	);
};

export default TitleBig;
