import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const initialState = {
  blogPostsList: [],
  error: false,
  loading: false,
};

export const getBlogPostsList = createAsyncThunk(
  "blogPostsList",
  async (appLang, { rejectWithValue }) => {
    const locale = appLang === "en" ? "en" : "pl-PL";
    try {
      const response = await axios.get(
        `https://cms.sunmetric.pl/api/posts?populate=image&locale=${locale}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);

export const blogPostsListSlice = createSlice({
	name: 'blogPostsList',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBlogPostsList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getBlogPostsList.fulfilled, (state, action) => {
			state.blogPostsList = action.payload.data;
			state.error = false;
			state.loading = false;
		})
		builder.addCase(getBlogPostsList.rejected, (state) => {
			state.error = true;
			state.loading = false;
		})
	}
});