import React from "react";
import "./Privacy.scss";
import TitleBig from "../../atoms/TitleBig/TitleBig";
import { Link } from "react-router-dom";
import paragraph from "../../../assets/icons/paragraph.svg";
import Header from "../../organisms/Header/Header";
import privacy from "../../../assets/img/privacy-img.svg";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy">
      <Header title1={t("privacy.header")} img={privacy} />
      <div className="rules-wrapper">
        <div className="rules-content">
          <TitleBig title={t("privacy.title")} />
          <p>
            Administratorem Strony jest H2B LABS spółka z ograniczoną
            odpowiedzialnością z siedzibą w Gdańsku przy ul. Abrahama 1A,
            zarejestrowana w Sądzie Rejonowym Gdańsk – Północ w Gdańsku, VIII
            Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS:
            0000874598, o kapitale zakładowym w wysokości 11 600 PLN, REGON:
            385909404, NIP: 5851492684 <span>[Administrator]</span>.
          </p>
          <p>
            W celu skontaktowania się z Administratorem można napisać wiadomość
            pod adres e-mail: office@sunmetric.io lub tradycyjnie, za
            pośrednictwem poczty na adres: 80-307 Gdańsk, ul. Abrahama 1A.{" "}
          </p>
          <p>
            Polityka Prywatności dedykowana jest zarówno do osób odwiedzających
            stronę internetową – sunmetric.io, użytkowników aplikacji webowej
            Sunmetric, niezależnie od tego, czy działają oni jako konsumenci,
            czy przedsiębiorcy. Polityka Prywatności jest częścią{" "}
            <Link to={{ pathname: "/rules" }}> Regulaminu Sunmetric</Link>.
          </p>
          <p>
            Naszym celem jest należyte informowanie o sprawach związanych z
            przetwarzaniem danych osobowych, szczególnie wobec treści nowych
            przepisów o ochronie danych osobowych, w tym rozporządzenia
            Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE <span>(„RODO”)</span>. Z tego względu,
            w niniejszym dokumencie informujemy o podstawach prawnych
            przetwarzania danych osobowych, sposobach ich zbierania i
            wykorzystywania, a także o prawach podmiotów danych z tym
            związanych.{" "}
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Dane osobowe i ich przetwarzanie</p>
          <p>
            Dane osobowe obejmują wszelkie informacje umożliwiające
            zidentyfikowanie osoby fizycznej („<span>Dane</span>”).
            Administrator pozyskuje dane osobowe w minimalnym zakresie,
            niezbędnym do realizacji celów określonych poniżej. Jego celem jest,
            aby każdy użytkownik korzystający ze strony, aplikacji, zarówno
            webowej, jak i mobilnej, czy formularzy kontaktowych był świadomy
            jakie dane osobowe są przetwarzane, w jakim celu, przez kogo oraz
            jakie prawa przysługują w związku z przetwarzaniem.{" "}
          </p>
          <p>
            Przetwarzanie danych nie zawsze odbywa się wprost. Specyfika strony,
            jak i aplikacji powoduje, że Administrator pozyskuje dane także
            pośrednio korzystając m.in. z tzw. plików Cookies. Podawanie danych
            osobowych jest dobrowolne, jednak część z nich może być niezbędna do
            zapewnienia prawidłowego korzystania ze strony i usług
            Administratora.{" "}
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">
            W jaki sposób, w oparciu o jakie podstawy prawne i jakiego typu dane
            osobowe przetwarza Administrator?
          </p>
          <p>
            Pragniemy zachować przejrzystość w zakresie sposobów i podstaw
            prawnych przetwarzania danych osobowych, a także celów, dla których
            Administrator przetwarza dane osobowe. Dbamy o to, by każdorazowo
            wskazać niezbędne informacje w tym zakresie każdej osobie, której
            dane osobowe przetwarzamy jako administrator danych. Kierując się
            tym, by nasze wyjaśnienie tych kwestii było jak najbardziej
            czytelne, przedstawiamy poniższe zestawienie operacji przetwarzania
            danych osobowych.
          </p>
          <p>
            Jednocześnie pragniemy wskazać, że ilekroć przetwarzamy dane osobowe
            na podstawie prawnie uzasadnionego interesu administratora danych,
            staramy się przeanalizować i zrównoważyć nasz interes oraz
            potencjalny wpływ na osobę, której dane dotyczą (pozytywny, jak i
            negatywny) oraz prawa tej osoby wynikające z przepisów o ochronie
            danych osobowych. Nie przetwarzamy danych osobowych w oparciu o nasz
            uzasadniony interes w przypadku, gdy dojdziemy do wniosku, że wpływ
            na osobę, której dane dotyczą, przeważałby nad naszymi interesami
            (wówczas możemy przetwarzać dane osobowe, jeśli np. posiadamy
            odpowiednią zgodę lub wymagają tego lub zezwalają na to przepisy
            prawa).
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Informacje ogólne</p>
          <p>
            Osoby fizyczne odwiedzające Stronę, korzystające z aplikacji lub
            kontaktujące się z Nami za pośrednictwem formularza kontaktowego na
            Stronie, mają kontrolę nad danymi osobowymi, które nam podają.
            Strona ogranicza zbieranie i wykorzystywanie informacji o ich
            użytkownikach do niezbędnego minimum, wymaganego do świadczenia na
            ich rzecz usług na pożądanym poziomie, stosownie do art. 18 ustawy z
            dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.
          </p>
          <p className="bold">
            Przetwarzanie danych osobowych osób kontaktujących się z
            Administratorem w celu uzyskania informacji o ofercie lub
            podzielenia się uwagami dotyczącymi usług Administratora, a także
            kontaktujących się w celu zawarcia umowy z Administratorem – dotyczy
            użytkowników prowadzących działalność gospodarczą.
          </p>
          <p>
            Od osób fizycznych, które kontaktują się z Administratorem w celu
            uzyskania informacji o ofercie lub podzielenia się uwagami
            dotyczącymi usług, a także kontaktujących się w celu zawarcia umowy
            zbieramy następujące dane osobowe: imię i nazwisko, stanowisko,
            adres działalności i nazwę firmy, adres e-mail, ewentualnie dane
            identyfikacyjne do narzędzia komunikacyjnego np. Teams, Whatsapp,
            Slack.{" "}
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Rejestracja na Stronie</p>
          <p>
            Dane osobowe, które nam podajesz rejestrując się na Stronie, celem
            skorzystania z aplikacji webowej, tj. imię, nazwisko, Twój adres,
            adres poczty elektronicznej, numer telefonu, dane dotyczące
            prowadzonej działalności, treści niezbędne do wygenerowania raportu,
            o którym mowa w Regulaminie, które pośrednio mogą wskazywać także
            konkretną osobę fizyczną, przetwarzane są:
          </p>
          <p>
            1. w zakresie niezbędnym do nawiązania, ukształtowania treści,
            zmiany, rozwiązania i prawidłowej realizacji usług świadczonych
            drogą elektroniczną;
          </p>
          <p>
            2. w celu obsługi i zapewnienia działania Twojego konta użytkownika
            na Stronie,
          </p>
          <p>
            3. w celu realizacji usług w zakresie umieszczania ogłoszenia na
            Stronie,{" "}
          </p>
          <p>4. w celu rozpatrywania składanych reklamacji,</p>
          <p>
            5. w celu obsługi innych zgłoszeń lub zapytań, które możesz do nas
            kierować.
          </p>
          <p>
            Jeżeli rejestrujesz się lub logujesz do Konta za pośrednictwem
            Serwisów Zewnętrznych (np. Facebook, Google) możemy dodatkowo
            przetwarzać Twoje dane osobowe tj. Twoje informacje w Serwisach
            Zewnętrznych.
          </p>
          <p>
            W każdym z powyższych przypadków podstawą prawną przetwarzania
            danych, w zasadniczym zakresie, jest niezbędność do wykonania umowy,
            którą zawierasz ze Spółką, bądź podjęcie działań zmierzających do
            jej zawarcia (art. 6 ust. 1 lit. b RODO).
          </p>
          <p>
            Ponadto, w celu wywiązania się z obowiązków nałożonych na nas
            przepisami prawa, np. przez ustawę o rachunkowości lub przepisy
            podatkowe (np. wystawianie i przechowywanie faktur oraz dokumentów
            księgowych) będziemy również przetwarzać Twoje dane dotyczące
            transakcji dokonanych na Stronie oraz informacje z konta
            użytkownika. Możemy także przetwarzać Twoje dane w celu wywiązania
            się przez nas z obowiązku stosowania tzw. zasady rozliczalności,
            czyli obowiązku wykazania przez Spółkę przestrzegania przepisów
            dotyczących ochrony danych osobowych. W przypadkach wymienionych w
            niniejszym akapicie podstawą prawną przetwarzania ww. danych jest
            ciążący na nas obowiązek prawny (art. 6 ust. 1 lit. c) RODO).
          </p>
          <p>
            Będziemy także przetwarzać następujące dane w oparciu o prawnie
            uzasadniony interes Spółki (art. 6 ust.1 lit. f) RODO):
          </p>
          <p>
            1. Twoje dane podane w pytaniu, skardze lub sugestii w celu
            udzielania odpowiedzi na Twoje pytania, skargi lub sugestie (nasz
            prawnie uzasadniony interes obejmuje możliwość reagowania na
            pytania, skargi i sugestie Klientów);
          </p>
          <p>
            2. Twoje dane wskazane w ankiecie, o której wypełnienie możemy Cię
            poprosić w celu badania satysfakcji naszych Klientów oraz określania
            jakości naszej obsługi (nasz prawnie uzasadniony interes obejmuje
            możliwość pozyskiwania informacji w celu polepszania standardów
            obsługi Klienta).
          </p>
          <p>
            3. Twoje dane, jak również dane innych użytkowników Strony w celu
            prowadzenia badań i analiz statystycznych oraz w celu udoskonalania
            oferowanych przez nas usług i towarów (nasz prawnie uzasadniony
            interes obejmuje możliwość pozyskiwania informacji w celu
            udoskonalania prowadzonej przez nas działalności)
          </p>
          <p>
            4. Twoje dane, jak również dane innych użytkowników Strony w celu
            zapewnienia bezpieczeństwa usług świadczonych przez nas drogą
            elektroniczną, w tym przeciwdziałania ewentualnym nadużyciom lub
            oszustwom (nasz prawnie uzasadniony interes obejmuje zapewnienie
            bezpieczeństwa usług, w tym przeciwdziałanie oszustwom i
            nadużyciom).
          </p>
          <p>
            5. Twoje dane w zakresie kierowania do Ciebie zaproszenia do
            uczestnictwa w programie lojalnościowym (nasz prawnie uzasadniony
            interes obejmuje prowadzenie działań marketingowych).
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Formularz kontaktowy</p>
          <p>
            Dane osobowe, które nam podajesz za pośrednictwem Formularza
            Kontaktowego znajdującego się na Stronie, w tym imię i nazwisko oraz
            dane kontaktowe (adres e-mail) przetwarzane są w zakresie niezbędnym
            do udzielenia odpowiedzi na wiadomość otrzymaną za pośrednictwem
            Formularza oraz nawiązanie z Tobą w tym celu kontaktu.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Informacje handlowe – zgoda marketingowa</p>
          <p>
            Dane osobowe, które nam podajesz w celu otrzymywanie informacji
            handlowych i marketingowych ze strony Administratora, tj. imię,
            nazwisko, adres poczty elektronicznej lub numer telefonu, dane
            dotyczące prowadzonej działalności gospodarczej przetwarzane są w
            zakresie niezbędnym do przesłania Ci ww. informacji w oparciu o
            uzyskaną uprzednio od Ciebie zgodę. Taka zgoda może być wycofana w
            każdym czasie.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Linki do innych stron</p>
          <p>
            Strona może zawierać linki do innych stron. Jeśli klikniesz na link
            innej strony, zostaniesz do niej przekierowany. Należy pamiętać, że
            te zewnętrzne podmioty nie są przez nas obsługiwane. Dlatego
            zalecamy niezależnie - zapoznanie się z polityką prywatności tych
            stron. Nie mamy kontroli i nie ponosimy odpowiedzialności za treść,
            politykę prywatności lub praktyki stosowane przez strony trzecie.
          </p>
          <p>Umożliwiamy logowanie się do naszych Usług za pośrednictwem:</p>
          <p>
            • konta użytkownika na Facebooku. Jest to usługa spółki Meta
            Platforms Ireland Ltd. (4 Grand Canal Square, Grand Canal Harbour,
            Dublin 2, Irlandia). Więcej informacji znajdziesz pod adresem:{" "}
            <a
              href="https://facebook.com/help/2230503797265156"
              target="_blank"
              rel="noreferrer"
            >
              www.facebook.com/help/2230503797265156
            </a>
            .
          </p>
          <p>
            • konta Google użytkownika. Jest to usługa firmy Google Ireland Ltd.
            (Gordon House, 4 Barrow Street, Dublin, Irlandia). Więcej informacji
            znajdziesz pod adresem:{" "}
            <a
              href="https://support.google.com/accounts/answer/10130420"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/accounts/answer/10130420
            </a>
            .
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Cookies</p>
          <p>
            Praktycznie każda strona internetowa korzysta obecnie z tzw. plików
            Cookies, czyli plików, który służą do automatycznego zbierania
            danych osobowych od użytkowników strony internetowej („Cookies”).
            Nie inaczej jest w przypadku Strony Administratora. Pozyskane w ten
            sposób informacje zapisywane są na komputerze bądź innym urządzeniu
            mobilnym użytkownika, który z niej korzysta.{" "}
          </p>
          <p>
            Podstawowe cele dla wykorzystywania Cookies są dwa: utrzymanie sesji
            użytkownika i jej zapisanie oraz zapewnienie bezpieczeństwa (np.
            przy wykrywaniu nadużyć). W tym zakresie korzystanie z plików
            Cookies jest niezbędne.{" "}
          </p>
          <p>
            W dalszym zakresie Cookies może nie są niezbędne, ale w znacznym
            stopniu ułatwiają korzystanie ze strony. Wykorzystuje się je m.in. w
            celu:
          </p>
          <p>
            zapamiętania określonych wyborów użytkownika co do wyświetlania
            określonego komunikatu lub wyświetlanie go określoną liczbę razy,{" "}
          </p>
          <p>monitorowania aktywności użytkownika na stronie,</p>
          <p>
            zbierania anonimowych, zbiorczych statystyk pozwalających na
            poprawianie funkcjonalności strony,
          </p>
          <p>w celach marketingowych.</p>
          <p>
            W tym zakresie Administrator korzysta z usług podmiotów
            zewnętrznych, których lista ewoluuje w zależności od ukształtowania
            rynku oraz możliwości technicznych. Do tych podmiotów zalicza się:
          </p>
          <p>
            Google Analytics (Polityka Prywatności Google) – za jego
            pośrednictwem Administrator weryfikuje użytkownika, który już
            wcześniej korzystał ze strony, obserwuje ruch oraz zachowanie
            użytkowników na stronie.{" "}
          </p>
          <p>
            Należy podkreślić, że na żadnym etapie użytkownik nie jest
            zobowiązany do akceptacji Cookies (za wyjątkiem Cookies
            technicznych, niezbędnych do prawidłowego funkcjonowania strony
            internetowej). Za pośrednictwem przeglądarki internetowej istnieje
            możliwość wprowadzenia takiej konfiguracji, która uniemożliwi
            przechowywanie Cookies na komputerze, bądź innym urządzeniu mobilnym
            użytkownika. Istnieje także możliwość usunięcia obecnych Cookies.
            Brak akceptacji Cookies może jednak negatywnie wpłynąć na działanie
            strony, a w niektórych przypadkach nawet uniemożliwić korzystanie z
            określonych funkcji.
          </p>
          <p>
            Jeśli chcesz dowiedzieć się, jak zarządzać plikami cookies, w tym
            jak wyłączyć ich obsługę w przeglądarce, możesz skorzystać z pliku
            pomocy przeglądarki. Z informacjami na ten temat można zapoznać się
            wciskając klawisz F1 w przeglądarce. Ponadto odpowiednie wskazówki
            znajdują się na następujących podstronach, w zależności od używanej
            przeglądarki
          </p>
          <p>
            Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej
            chwili możesz usunąć pliki Cookies lub blokować wykorzystanie plików
            cookies w przyszłości.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Logi dostępowe</p>
          <p>
            W oparciu o analizę logów dostępu Administrator zbiera informacje
            dotyczące korzystania ze strony przez użytkowników oraz ich adresy
            IP. Celem pozyskiwania tych danych jest ewentualna diagnoza
            problemów związanych z pracą serwera, ocena ryzyka ewentualnych
            naruszeń bezpieczeństwa oraz zarządzanie stroną.{" "}
          </p>
          <p>
            Powyższe informacje umożliwiają stworzenie zbiorczych zestawień
            statystycznych, które mogą być ujawniane osobom trzecim (kwestie
            związane np. z oglądalnością strony). Niekiedy także uprawniony
            organ państwa, w związku z prowadzonymi postępowaniami także może
            zobowiązać Administratora do ujawnienia informacji o IP konkretnego
            użytkownika.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Automatyczne przetwarzanie danych osobowych</p>
          <p>
            Informacje, które gromadzimy w związku z korzystaniem z naszej
            Strony mogą być przetwarzane w sposób zautomatyzowany (w tym w
            formie profilowania), jednakże nie będzie to wywoływać wobec osoby
            fizycznej żadnych skutków prawnych lub w podobny sposób istotnie
            wpływać na jej sytuację. Szczególną wagę przykładamy do kwestii
            profilowania i wskazujemy, że:
          </p>
          <p>
            - na potrzeby profilowania nie przetwarzamy żadnych danych
            wrażliwych,
          </p>
          <p>
            - na potrzeby profilowania przetwarzamy z reguły takie dane, które
            zostały przez nas zagregowane,
          </p>
          <p>
            - jeśli nie możemy osiągnąć celu inaczej niż poprzez profilowanie
            danych osobowych nie zspeudonimizowanych lub niezagregowanych,
            wykorzystujemy do tego typowe dane: adres e-mail i IP lub Cookies,
          </p>
          <p>
            - profilujemy w celu analizy lub prognozy osobistych preferencji
            oraz zainteresowań osób korzystających z naszej Strony i
            dopasowywania treści znajdujących się na naszej Stronie do tych
            preferencji,
          </p>
          <p>
            - profilujemy w celach marketingowych, tj. dopasowania oferty
            marketingowej do ww. preferencji.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Podstawy Prawne Przetwarzania</p>
          <p>
            Dane osobowe użytkowników strony są przetwarzane na podstawie
            obowiązków wynikających z przepisów obowiązującego prawa
            (wszystkie), uzyskanej zgody (informacje handlowe), konieczności
            wykonania umowy (realizacja żądania) oraz uzasadnionego interesu
            Administratora.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Jak długo Administrator przechowuje dane?</p>
          <p>
            Okres przechowywania danych uzależniony jest od podstawy prawnej ich
            przetwarzania:
          </p>
          <p>
            - w przypadku wyrażenia zgody (np. na udostępnienie informacji
            handlowych) okres ten trwa aż do momentu jej wycofania,
          </p>
          <p>
            - przy przetwarzaniu danych w celu wykonanie umowy – na czas
            realizacji umowy oraz okres przedawnienia roszczeń z niej
            wynikających,
          </p>
          <p>
            - przy przetwarzaniu danych w oparciu o obowiązki wynikające z
            obowiązujących przepisów prawa Administrator przetwarza dane tak
            długo, ile jest to konieczne na podstawie tych przepisów,
          </p>
          <p>
            - przy przetwarzaniu danych w ramach realizacji prawnie
            uzasadnionego interesu Administratora, przetwarzanie trwa tak długo,
            dopóki trwa ten interes.{" "}
          </p>
          <p>
            Okres przetwarzania powyższych danych może ulec przedłużeniu w
            przypadku, gdy przetwarzanie danych jest konieczne do określenia
            oraz dochodzenia roszczeń, bądź obrony przed roszczeniami. Po
            upływie powyższych okresów dane są niezwłocznie usuwane lub
            zanonimizowane.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Odbiorcy danych</p>
          <p>
            Niekiedy Administrator ma prawo przekazywać dane użytkowników,
            jeżeli jest to konieczne dla wykonywania usług, spełnienia
            obowiązków i należytego wywiązania się z obowiązujących przepisów
            prawa.{" "}
          </p>
          <p>
            Administrator może korzystać z usług zewnętrznych podmiotów w
            zakresie wykonywania niektórych zadań np. przy przechowywaniu
            danych, obsłudze księgowej, obsłudze prawnej, obsłudze
            marketingowej, czy informatycznej. Jeśli zachodzi taka potrzeba,
            dane mogą otrzymać także uprawnione to tego władze.{" "}
          </p>
          <p>
            Przy powierzaniu danych podmiotom podwykonawczym w realizacji celu
            Administratora, nie dochodzi do zmiany Administratora danych i to
            Administrator w dalszym ciągu odpowiada za ich bezpieczeństwo.{" "}
          </p>
          <p>
            Dane użytkowników mogą być przekazywane następującym podmiotom:{" "}
          </p>
          <p>
            - ww. podmioty przetwarzające dane osobowe na mocy umów powierzenia
            przetwarzania danych osobowych (tzw. procesorzy),
          </p>
          <p>
            - podmioty świadczące usługi:
            <span className="point">- hostingowe, </span>
            <span className="point">
              - cyfrowe, w tym usługi chmury obliczeniowej,
            </span>
            <span className="point">
              - dostępu do oprogramowania służącego do komunikacji.
            </span>
          </p>
          <p>
            - wykonawcy i podwykonawcy Administratora świadczący usługi z
            zakresu dostarczania oprogramowania, usługi serwisowania
            oprogramowania lub sprzętu, z którego korzysta, Administrator, a
            także dostawcy produktów, z pomocy których korzystamy,
          </p>
          <p>
            - firmy windykacyjne (przy czym dane osobowe przekazujemy tylko w
            takim zakresie, w jakim jest to rzeczywiście niezbędne dla
            osiągnięcia danego celu),
          </p>
          <p>
            - audytorzy i biegli rewidenci, doradcy prawni, doradcy podatkowi,
          </p>
          <p>
            - organy nadzorujące przestrzeganie prawa, organy regulacyjne i inne
            organy administracji publicznej.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">
            Czy i komu Administrator udostępnia dane użytkowników?{" "}
          </p>
          <p>
            Poza powyższym zakresem dane nie są udostępniane podmiotom trzecim,
            za wyjątkiem sytuacji, gdzie użytkownik wyraźnie wyraża na to
            dobrowolną zgodę (którą w każdym momencie może odwołać),
            udostępnienie danych jest konieczne w celu realizacji umowy lub
            świadczenia usług oraz w szczególnych przypadkach, jeżeli uprawniony
            podmiot zwraca się o udostepnienie danych na podstawie powszechnie
            obowiązujących przepisów prawa (np. jest to organ ścigania).{" "}
          </p>
          <p>
            Każda z powyżej wymienionych sytuacji jest przez Administratora
            wnikliwie analizowana, a ostateczne przekazanie danych następuje
            wyłącznie w przypadku potwierdzenia istnienia ważnej i skutecznej
            podstawy prawnej dla żądania ujawnienia danych użytkowników przez te
            podmioty.{" "}
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">
            Udostępnianie danych podmiotom spoza EOG (Europejskiego Obszaru
            Gospodarczego)
          </p>
          <p>
            Pozyskane dane Administrator może przekazywać innym podmiotom,
            wyłącznie, gdy zezwala mu na to podstawa prawna.
          </p>
          <p>
            Niektórzy z dostawców usług dla Administratora mają siedzibę poza
            Europejskim Obszarem Gospodarczym (EOG). Przy przekazywaniu danych
            poza obszar EOG Administrator zachowuje wzmożoną ostrożność.
            Weryfikuje, czy dostawy gwarantują wysoki stopień ochrony danych
            osobowych, zgodny z wymogami prawnymi obowiązującymi na terenie EOG
            oraz przyjętą linią orzeczniczą m.in. orzeczeniem Trybunału
            Sprawiedliwości Unii Europejskiej z dnia 27 lipca 2020 r. Schrems
            II. Minimalizuje zakres danych wysyłanych poza terytorium EOG, a w
            przypadku zastosowania SCC (standardowych klauzul umownych
            przyjętych przez Komisję Europejską) weryfikuje czy istnieje ryzyko
            naruszenia ochrony danych osobowych przez podmioty spoza terytorium
            EOG. Bada m.in. proces zabezpieczenia danych oraz czy udostępnione
            dane mogą potencjalnie stanowić przedmiot zainteresowań państw
            trzecich.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Ochrona danych</p>
          <p>
            Administrator wykorzystuje wszelkie dostępne środki fizyczne,
            techniczne i organizacyjne, aby zapewnić prawidłową ochronę danych
            osobowych. W szczególności zabezpiecza je przed zniszczeniem,
            przypadkową utratą, ujawnieniem osobom nieuprawnionym, zmianą i
            weryfikuje zakres dostępu. Administrator realizuje prawa osób,
            których dotyczy przetwarzanie danych. Prowadzi odpowiedni rejestr
            czynności przetwarzania danych, notyfikuje odpowiednim urzędom oraz
            osobom, których konkretne dane dotyczą naruszenia związane z
            przetwarzaniem danych osobowych.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Przysługujące Prawa</p>
          <p>
            Użytkownikowi w związku z przetwarzaniem jego danych osobowych
            przysługują następujące prawa (art. 12-21 RODO):
          </p>
          <p>
            1. <span>do informacji</span> w zakresie przetwarzanych danych
            osobowych tzw. „obowiązek informacyjny” (zgodnie z art. 12 i 13
            RODO),
          </p>
          <p>
            2. <span>dostępu</span> do treści swoich danych osobowych (zgodnie z
            art. 15 RODO),
          </p>
          <p>
            3. wniesienia żądania <span>sprostowania</span> danych osobowych
            (zgodnie z art. 16 RODO), tj. poprawienia danych nieprawidłowych
            oraz uzupełnienia danych niekompletnych,
          </p>
          <p>
            4. wniesienia żądania ograniczenia przetwarzania danych osobowych
            (zgodnie z art. 18 RODO),
          </p>
          <p>
            5. prawo do wniesienia żądania przeniesienia swoich danych osobowych
            do innego Administratora (zgodnie z art. 20 RODO),
          </p>
          <p>
            6. wniesienia sprzeciwu wobec przetwarzania danych z przyczyn
            związanych ze szczególną sytuacją (zgodnie z art. 21 ust. 1 RODO),
            jednakże prawo to nie ma charakteru bezwzględnego - tzn. mimo
            wniesienia sprzeciwu w dalszym ciągu Administrator może przetwarzać
            dane osobowe jeżeli wykaże, że istnieją ważne, prawnie uzasadnione
            podstawy do przetwarzania, nadrzędne wobec praw i wolności lub
            podstawy do ustalenia, dochodzenia lub obrony roszczeń,
          </p>
          <p>
            7. wniesienia sprzeciwu wobec przetwarzania danych osobowych
            dokonywanego na potrzeby marketingu bezpośredniego, w zakresie, w
            jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.
            Sprzeciw ten nie wymaga uzasadnienia ani warunków skuteczności.
            Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania,
            którego dokonano na podstawie zgody przed jej wycofaniem.
          </p>
          <p>
            8. wniesienia żądania usunięcia danych osobowych (zgodnie z art. 17
            RODO) - tzw. „prawo do bycia zapominanym”, prawo to przysługuje gdy
            Administrator przetwarza dane niezgodnie z prawem, gdy użytkownik
            złoży sprzeciw wobec przetwarzania danych w celach marketingowych
            oraz gdy dane musza zostać usunięte, aby Administrator wywiązał się
            z obowiązku wynikającego z przepisów prawa.
          </p>
          <p>
            Można zgłosić sprzeciw wobec przetwarzania przez nas danych
            Użytkownika poprzez odłączenie swojego konta w usłudze logowania
            Serwisów Zewnętrznych od posiadanego Konta w naszych Usługach.
            Więcej informacji można uzyskać pod adresem:{" "}
          </p>
          <p>
            • logowanie poprzez konto na Facebooku:{" "}
            <a
              href="https://facebook.com/help/2230503797265156"
              target="_blank"
              rel="noreferrer"
            >
              www.facebook.com/help/2230503797265156
            </a>
          </p>
          <p>
            • logowanie poprzez konto Google:{" "}
            <a
              href="https://support.google.com/accounts/answer/10130420"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/accounts/answer/10130420
            </a>
          </p>

          <p>
            Ponadto Użytkownikowi przysługuje prawo wniesienia skargi do organu
            nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Prywatność dzieci</p>
          <p>
            Niniejszy serwis nie jest skierowany do osób poniżej 13 roku życia.
            Nie zbieramy świadomie danych osobowych od dzieci poniżej 13 roku
            życia. W przypadku odkrycia, że dziecko poniżej 13 roku życia
            przekazało nam dane osobowe, natychmiast usuwamy je z naszych
            serwerów. Jeśli jesteś rodzicem lub opiekunem i wiesz, że Twoje
            dziecko przekazało nam dane osobowe, prosimy o kontakt, abyśmy mogli
            podjąć odpowiednie działania.
          </p>
          <img src={paragraph} alt="p" />
          <p className="title">Aktualizacja Polityki Prywatności</p>
          <p>
            Administrator deklaruje, że niniejsza Polityka Prywatności będzie na
            bieżąco aktualizowana m.in. w przypadku wprowadzenia nowych wymagań
            prawnych, bądź wytycznych. Administrator uwzględni także zmiany
            technologii, wykorzystywanej w procesie przetwarzania danych oraz
            ich ochrony, zmiany celów przetwarzania, kategorii pozyskiwanych
            danych oraz podstaw prawnych dla ich przetwarzania..
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
