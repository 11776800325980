import React from "react";
import Title from "../../atoms/Title/Title";
import "./WhatIsSunmetric.scss";
import Text from "../../atoms/Text/Text";
import question from "../../../assets/icons/question-sign.svg";
import { useTranslation } from "react-i18next";

const WhatIsSunmetric = () => {
  const { t } = useTranslation();
  return (
    <div className="container-full-width what-is-sunmteric">
      <div className="sunmteric-description">
        <Title text={t("home.section1.title")} size="large" />
        <div className="inner">
          <Text text={t("home.section1.text1")} />
          <img src={question} alt="question icon" />
          <div className="columns-container">
            <Text text={t("home.section1.text2")} />
            <Text text={t("home.section1.text3")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsSunmetric;
