import React from "react";
import "./ReportInfo.scss";
import Title from "../../atoms/Title/Title";
import Image from "../../atoms/Image/Image";
import markerIcon from "../../../assets/img/report-content/marker.svg";
import areaIcon from "../../../assets/img/report-content/area.svg";
import impedimentsIcon from "../../../assets/img/report-content/impediments.svg";
import mapImg from "../../../assets/img/report-content/map.png";
import kwImg from "../../../assets/img/report-content/kwImg.svg";
import electricityImg from "../../../assets/img/report-content/electricityImg.svg";
import groundsIcon from "../../../assets/img/report-content/grounds.svg";
import roadsIcon from "../../../assets/img/report-content/roads.svg";
import mpzpIcon from "../../../assets/img/report-content/mpzp.svg";
import electricityIcon from "../../../assets/img/report-content/electricity.svg";
import eletricityMapIcon from "../../../assets/img/report-content/electricityMap.svg";
import gpzIcon from "../../../assets/img/report-content/gpz.svg";
import kwIcon from "../../../assets/img/report-content/kw.svg";
import areaMapIcon from "../../../assets/img/report-content/areaMapIcon.svg";
import areaMapImg from "../../../assets/img/report-content/areaMapImg.svg";
import roadsImg from "../../../assets/img/report-content/roadImg.svg";
import Button from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

const ReportInfo = () => {
  const { t } = useTranslation();

  const handleDownload = async () => {
    const response = await fetch("/pdf/Raport.pdf");
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Raport Sunmteric.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const InfoSection = ({ img, title, text }) => {
    return (
      <div className="info-section-wrappper">
        <div className="title">
          <Image img={img} />
          <p>{title}</p>
        </div>
        <p className="text">{text}</p>
      </div>
    );
  };

  return (
    <div className="report-info-container">
      <div className="report-info">
        <Title text={t("report.titleBig")} />

        <div className="report-info-section-container">
          <div className="report-info-section">
            <div className="main-content">
              <Title text={t("report.basicInfo.header")} size="small" />
              <InfoSection
                img={markerIcon}
                title={t("report.basicInfo.title1")}
                text={t("report.basicInfo.text1")}
              />
              <InfoSection
                img={areaIcon}
                title={t("report.basicInfo.title2")}
                text={t("report.basicInfo.text2")}
              />
              <InfoSection
                img={impedimentsIcon}
                title={t("report.basicInfo.title3")}
                text={t("report.basicInfo.text3")}
              />
            </div>
            <div className="img-wrapper map">
              <Image img={mapImg} />
            </div>
          </div>
          <div className="report-info-section revers">
            <div className="img-wrapper road">
              <Image img={roadsImg} />
            </div>
            <div className="main-content">
              <InfoSection
                img={groundsIcon}
                title={t("report.basicInfo.title4")}
                text={t("report.basicInfo.text4")}
              />
              <InfoSection
                img={roadsIcon}
                title={t("report.basicInfo.title5")}
                text={t("report.basicInfo.text5")}
              />
              <InfoSection
                img={mpzpIcon}
                title={t("report.basicInfo.title6")}
                text={t("report.basicInfo.text6")}
              />
            </div>
          </div>
        </div>

        <div className="report-info-section-container">
          <div className="report-info-section">
            <div className="main-content">
              <Title text={t("report.electricity.header")} size="small" />
              <InfoSection
                img={electricityIcon}
                title={t("report.electricity.title1")}
                text={t("report.electricity.text1")}
              />
              <InfoSection
                img={gpzIcon}
                title={t("report.electricity.title2")}
                text={t("report.electricity.text2")}
              />
              <InfoSection
                img={eletricityMapIcon}
                title={t("report.electricity.title3")}
                text={t("report.electricity.text3")}
              />
            </div>
            <div className="img-wrapper electricity">
              <Image img={electricityImg} />
            </div>
          </div>
        </div>

        <div className="report-info-section-container">
          <div className="report-info-section revers">
            <div className="img-wrapper kw">
              <Image img={kwImg} />
            </div>
            <div className="main-content">
              <Title text={t("report.kw.header")} size="small" />
              <div className="info-section-wrappper-simple">
                <div className="title">
                  <Image img={kwIcon} />
                  <p>{t("report.kw.header")}</p>
                </div>
                <p className="text">{t("report.kw.text")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="report-info-section-container">
          <div className="report-info-section">
            <div className="main-content">
              <Title text={t("report.areaMap.header")} size="small" />
              <div className="info-section-wrappper-simple">
                <div className="title">
                  <Image img={areaMapIcon} />
                  <p>{t("report.areaMap.header")}</p>
                </div>
                <p className="text">{t("report.areaMap.text")}</p>
              </div>
            </div>
            <div className="img-wrapper areaMap">
              <Image img={areaMapImg} />
            </div>
          </div>
        </div>

        <Button text={t("button.exampleReport")} onClick={handleDownload} />
      </div>
    </div>
  );
};

export default ReportInfo;
