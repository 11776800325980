import React from "react";
import Step from "../../molecules/Step/Step";
import "./StepByStep.scss";
import analysis from "../../../assets/img/step1-analysis.png";
import select from "../../../assets/img/step1-select.png";
import build from "../../../assets/img/step4-build.png";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import Button from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

const StepByStep = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 1,
      img: select,
      bigTitle: t("home.section2.subtitle1"),
      description: (
        <>
          <Text text={t("home.section2.text1")} />
        </>
      ),
    },
    {
      id: 2,
      img: analysis,
      bigTitle: t("home.section2.subtitle2"),
      description: (
        <>
          <Text text={t("home.section2.text2")} />
        </>
      ),
    },
    {
      id: 3,
      img: build,
      bigTitle: t("home.section2.subtitle3"),
      description: (
        <>
          <Text text={t("home.section2.text3")} />
        </>
      ),
    },
  ];

  return (
    <div className="step-by-step container">
      <Title text={t("home.section2.title")} size="large" />
      {steps.map((step) => (
        <Step key={step.id} step={step} />
      ))}
    </div>
  );
};

export default StepByStep;
