import "./Banner.scss";
import banner from "../../../assets/img/banner.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Banner = () => {
  const [windowScroll, setWindowScroll] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    const updateWindowScroll = () => {
      setWindowScroll(window.scrollY);
    };

    updateWindowScroll();

    window.addEventListener("scroll", updateWindowScroll);
    return () => {
      window.removeEventListener("scroll", updateWindowScroll);
    };
  }, []);

  const isShown = window.scrollY < 300;

  if (pathname !== "/") {
    return;
  }
  return (
    <div
      className={`main-banner${isShown ? " main-banner-shown" : ""}`}
      style={{
        transform: `translateY(${windowScroll / 5}px)`,
      }}
    >
      <div className="main-banner-inner">
        <img src={banner} alt="" />
      </div>
    </div>
  );
};

export default Banner;
