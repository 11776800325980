import "./Footer.scss";
import banner from "../../../assets/img/banner.svg";
import logoNetrix from "../../../assets/img/logo-netrix-.svg";
import logoCzysta from "../../../assets/img/logo-czysta.svg";
import logoSunmteric from "../../../assets/img/logo-sunmetric/logoC.png";
import { ReactComponent as LogoFb } from "../../../assets/img/logo-fb.svg";
import { ReactComponent as LogoLinkedin } from "../../../assets/img/logo-lnkd.svg";
import FooterLink from "../../atoms/FooterLink/FooterLink";
import Title from "../../atoms/Title/Title";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <img
          src={logoSunmteric}
          alt="sunmteric logo"
          className="sunmetric-logo"
        />
        <Title text="Dane kontaktowe" size="small" />

        <div className="address-container">
          <div>
            <p>
              H2B LABS sp. z o.o. <br /> Abrahama 1A <br />
              80-307 Gdańsk, {t("footer.poland")}
            </p>
            <p>
              {t("footer.KRS")} 0000874598 <br />
              {t("footer.NIP")} PL5851492684
            </p>
          </div>
        </div>

        <div className="contact-container">
          <p>
            Email: <a href="mailto:office@sunmetric.io"> office@sunmetric.io</a>
            <br />
            {t("footer.phone")}:<a href="tel:+48601192313"> +48 601 192 313</a>
          </p>
          <div className="sm-links">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/sunmetric/"
              aria-label="linkedin icon"
            >
              <LogoLinkedin />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/sunmetricAi/"
              aria-label="facebook icon"
            >
              <LogoFb />
            </a>
          </div>
        </div>

        <div className="links-container desktop">
          <FooterLink link="/" linkTitle={t("navigation.about")} />
          <FooterLink link="/report" linkTitle={t("navigation.report")} />
          <FooterLink link="/pricing" linkTitle={t("navigation.pricing")} />
          <FooterLink link="/contact" linkTitle={t("navigation.contact")} />
        </div>
        <Title text="Na skróty" size="small" />

        <div className="links-container mobile">
          <div>
            <FooterLink link="/" linkTitle={t("navigation.about")} />
            <FooterLink link="/report" linkTitle={t("navigation.report")} />
            <FooterLink link="/rules" linkTitle={t("navigation.rules")} />
            <FooterLink link="/privacy" linkTitle={t("navigation.privacy")} />
          </div>
          <div>
            <FooterLink link="/pricing" linkTitle={t("navigation.pricing")} />
            <FooterLink link="/contact" linkTitle={t("navigation.contact")} />
          </div>
        </div>

        <Title text={t("footer.cofinance")} size="small" />
        <div className="investors-logos">
          <img src={logoNetrix} alt="investor logo" />
          <img src={logoCzysta} alt="investor logo" />
        </div>
      </div>

      <div className="footer-banner">
        <small className="footer-banner-text">
          {t("footer.cofinanceText")}
        </small>
        <div className="footer-banner-img">
          <img src={banner} alt="investors banner" />
        </div>
        <div className="copyright">
          <p> {t("footer.copyright")}</p>
          <div className="other-links">
            <FooterLink link="/rules" linkTitle={t("navigation.rules")} />
            <FooterLink link="/privacy" linkTitle={t("navigation.privacy")} />
          </div>
        </div>
        <div className="footer-logo">
          <img src={logoSunmteric} alt="sunmteric logo" />
          <p>sunmetric 2023</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
