import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import "./ContactForm.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import Frame from "../../atoms/Frame/Frame";
import Button from "../../atoms/Button/Button";
import Title from "../../atoms/Title/Title";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";

const ContactForm = () => {
  const [formState, setFormState] = useState("default");
  const { t } = useTranslation();

  const submitForm = async (form) => {
    setFormState("loading");
    try {
      await axios.post(
        "https://mnlgc0o4hf.execute-api.eu-central-1.amazonaws.com/production/frontendwebsitecontact-production",
        {
          name: form.name,
          email: form.email,
          message: form.message,
        },
        {
          headers: {},
        }
      );
      setFormState("success");
      const timer = setTimeout(() => {
        setFormState("default");
      }, 10000);
      return () => clearTimeout(timer);
    } catch (e) {
      setFormState("error");
      const timer = setTimeout(() => {
        setFormState("default");
      }, 10000);
      return () => clearTimeout(timer);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        TOSAgremment: false,
        dataProcessingAgreement: false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(t("contact.form.fieldRequired")),
        email: Yup.string()
          .required(t("contact.form.fieldRequired"))
          .email(t("contact.form.incorrectEmail")),
        message: Yup.string().required(t("contact.form.fieldRequired")),
        TOSAgremment: Yup.bool().oneOf([true], t("contact.form.fieldRequired")),
        dataProcessingAgreement: Yup.bool().oneOf(
          [true],
          t("contact.form.fieldRequired")
        ),
      })}
      onSubmit={async (values, { resetForm }) => {
        await submitForm(values);
        resetForm();
      }}
      component={(props) => <Form {...props} formState={formState} />}
    />
  );
};

const Form = ({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  formState,
}) => {
  const { t } = useTranslation();

  return (
    <div className="contact-form-wrapper container-full-width">
      <Title text={t("contact.section1.title")} size="large" />
      <Frame>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="inner">
            <div className="col1">
              <label className="label" htmlFor="name">
                {t("contact.form.name")}
              </label>
              <TextField
                error={!!errors.name && touched.name}
                helperText={touched.name ? errors.name : null}
                hiddenLabel
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label className="label" htmlFor="email">
                {t("contact.form.email")}
              </label>
              <TextField
                error={!!errors.email && touched.email}
                helperText={touched.email ? errors.email : null}
                hiddenLabel
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col2">
              <label className="label" htmlFor="message">
                {t("contact.form.message")}
              </label>
              <TextField
                error={!!errors.message && touched.message}
                helperText={touched.message ? errors.message : null}
                hiddenLabel
                name="message"
                id="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                rows={5}
              />
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.TOSAgremment}
                  className={
                    !!errors.TOSAgremment && touched.TOSAgremment ? "error" : ""
                  }
                />
              }
              label={
                <>
                  <Trans
                    i18nKey="contact.form.tosAgreement"
                    components={[
                      <Link
                        to={{
                          pathname: "/rules",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      ></Link>,
                      <Link
                        to={{
                          pathname: "/privacy",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      ></Link>,
                    ]}
                  />
                  {!!errors.tosAgreement && touched.tosAgreement && (
                    <p className="errorMsg">{t("validation.fieldRequired")}</p>
                  )}
                </>
              }
              onChange={handleChange}
              name="TOSAgremment"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.dataProcessingAgreement}
                  className={
                    !!errors.dataProcessingAgreement &&
                    touched.dataProcessingAgreement
                      ? "error"
                      : ""
                  }
                />
              }
              label={
                <>
                  {t("contact.form.marketingAgreement")}
                  {!!errors.dataProcessingAgreement &&
                    touched.dataProcessingAgreement && (
                      <span className="errorMsg">
                        {t("contact.form.fieldRequired")}
                      </span>
                    )}
                </>
              }
              onChange={handleChange}
              name="dataProcessingAgreement"
            />
          </FormGroup>
          <Button text="wyślij" type="submit" />

          {formState === "success" && (
            <Alert severity="success">{t("contact.form.sent")}</Alert>
          )}
          {formState === "error" && (
            <Alert severity="error">{t("contact.form.error")}</Alert>
          )}
        </form>
      </Frame>
    </div>
  );
};
export default ContactForm;
