import Footer from "./page/organisms/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/pages/Home/Home";
import Blog from "./page/pages/Blog/Blog";
import Contact from "./page/pages/Contact/Contact";
import Pricing from "./page/pages/Pricing/Pricing";
import Privacy from "./page/pages/Privacy/Privacy";
import ScrollToTop from "./hooks/ScrollToTop";
import Rules from "./page/pages/Rules/Rules";
import BlogPost from "./page/pages/BlogPost/BlogPost";
import DrawerAppBar from "./page/organisms/Navigation/Navigation";
import ReportContent from "./page/pages/ReportContent/ReportContent";
import ScrollTopButton from "./page/organisms/ScrollTopButton/ScrollTopButton";
import Banner from "./page/atoms/Banner/Banner";
import NotFoundPage from "./page/pages/Error/Error";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <DrawerAppBar />
        <ScrollToTop />
        <ScrollTopButton />
        <Banner />
        <Routes>
          <Route path="/contact" element={<Contact />}></Route>{" "}
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/rules" element={<Rules />}></Route>
          <Route path="/report" element={<ReportContent />}></Route>
          {/* <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/:id" element={<BlogPost />}></Route> */}
          <Route exact path="/" element={<Home />}></Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
