import React from "react";
import "./Contact.scss";
import WhySunmetric from "../../organisms/WhySunmteric/WhySunmetric";
import Encouragement from "../../organisms/CTA/CTA";
import ContactForm from "../../organisms/ContactForm/ContactForm";
import ContactInfo from "../../organisms/ContactInfo/ContactInfo";
import Header from "../../organisms/Header/Header";
import mail from "../../../assets/img/mail-img.svg";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact">
      <Header
        title1={t("contact.header1")}
        title2={t("contact.header2")}
        img={mail}
      />
      <ContactForm />
      <ContactInfo />
      <WhySunmetric />
      <Encouragement contactButton={false} />
    </div>
  );
};

export default Contact;
