import { useState } from "react";
import "./Navigation.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Button from "../../atoms/Button/Button";
import Logo from "../../atoms/Logo/Logo";
import { Link } from "react-router-dom";
import priceTag from "../../../assets/icons/home/price-tag.svg";
import call from "../../../assets/icons/call.svg";
import cert from "../../../assets/icons/certification.svg";
import hambuger from "../../../assets/icons/menu-hamburger.png";
import close from "../../../assets/icons/close-btn.png";
import { useLocation } from "react-router-dom";
import { useDetectScroll } from "../../../hooks/DetectScroll";
import LangSelect from "../LangSelect/LangSelect";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const navLinks = [
    {
      link: "/report",
      title: i18n.t("navigation.report"),
      img: cert,
    },
    {
      link: "/pricing",
      title: i18n.t("navigation.pricing"),
      img: priceTag,
    },
    {
      link: "/contact",
      title: i18n.t("navigation.contact"),
      img: call,
    },
  ];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const isScrolled = useDetectScroll();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { pathname } = useLocation();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="menu-top-container">
      <AppBar
        component="nav"
        className={`menu-top ${isScrolled ? "scrolled" : ""}`}
        elevation={0}
      >
        <Toolbar>
          <Box className="logo-wrapper">
            <Logo />
            <LangSelect />
          </Box>

          <Box className="nav-links-desktop">
            {navLinks.map(({ link, title, img }) => (
              <Link
                to={{
                  pathname: link,
                }}
                key={link}
              >
                <img
                  src={img}
                  alt=""
                  className={`${pathname === link ? "active" : ""}`}
                />
                <p>{title}</p>
              </Link>
            ))}
          </Box>
          <div className="hamburger-container">
            <IconButton onClick={handleDrawerToggle} className="hamburger">
              <img src={hambuger} alt="menu-icon" aria-label="menu" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          className="nav-mobile-paper"
          anchor="top"
        >
          <div className="close-btn-container">
            <IconButton onClick={handleDrawerToggle} className="close-btn">
              <img src={close} alt="" />
            </IconButton>
          </div>
          <Box onClick={handleDrawerToggle}>
            <Box className="nav-links-mobile">
              {navLinks.map(({ link, title, img }) => (
                <Link
                  to={{
                    pathname: link,
                  }}
                  key={link}
                >
                  <img
                    src={img}
                    alt=""
                    className={`${pathname === link ? "active" : ""}`}
                  />
                  <p>{title}</p>
                </Link>
              ))}
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
