import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

const Button = ({
  color = "yellow",
  text,
  height,
  icon,
  pathname,
  linkTo,
  style,
  type = "button",
  onClick,
}) => {
  return (
    <button
      className={`button button-${color}`}
      style={{
        height: height,
        ...style,
      }}
      type={type}
      onClick={onClick}
    >
      {linkTo && (
        <a aria-label={text} target="_blank" rel="noreferrer" href={linkTo}>
          {icon && <img src={icon} alt={text} />}
          <p>{text}</p>
        </a>
      )}
      {pathname && (
        <Link to={{ pathname: pathname }}>
          {icon && <img src={icon} alt="icon" />}
          <p>{text}</p>
        </Link>
      )}
      {!linkTo && !pathname && text && <p>{text}</p>}
    </button>
  );
};

export default Button;
