import React from "react";
import "./Home.scss";
import Header from "../../organisms/HeaderHome/Header";
import WhatIsSunmetric from "../../organisms/WhatIsSunmetric/WhatIsSunmetric";
import StepByStep from "../../organisms/StepByStep/StepByStep";
import WhySunmetric from "../../organisms/WhySunmteric/WhySunmetric";
import Encouragement from "../../organisms/CTA/CTA";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="home-container">
      <Header title={t("home.header")} showBanner showImage />
      <WhatIsSunmetric />
      <StepByStep />
      <WhySunmetric />
      <Encouragement />
    </div>
  );
};

export default Home;
