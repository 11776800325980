import React from "react";
import "./Rules.scss";
import TitleBig from "../../atoms/TitleBig/TitleBig";
import paragraph from "../../../assets/icons/paragraph.svg";
import { Link } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import rules from "../../../assets/img/rules-img.svg";
import { useTranslation } from "react-i18next";

const Rules = () => {
  const { t } = useTranslation();

  return (
    <div className="rules">
      <Header title1={t("rules.header")} img={rules} />
      <div className="rules-wrapper">
        <div className="rules-content">
          <TitleBig title={t("rules.title")} />
          <div className="privacy-paragraph">
            <p className="title">1. Zasady ogólne</p>
            <p className="x-x">
              1.1. Regulamin określa zasady korzystania z usług dostępnych na
              Stronie Internetowej, dostępnej pod adresem{" "}
              <a
                href="http://app.sunmetric.pl"
                target="_blank"
                rel="noreferrer"
              >
                http://app.sunmetric.pl
              </a>
            </p>
            <p className="x-x">
              1.2. Administratorem Strony jest H2B LABS spółka z ograniczoną
              odpowiedzialnością z siedzibą w Gdańsku przy ul. Abrahama 1A,
              zarejestrowana w Sądzie Rejonowym Gdańsk – Północ w Gdańsku, VIII
              Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS:
              0000874598, o kapitale zakładowym w wysokości 11 600 PLN, REGON:
              385909404, NIP: 5851492684 [H2B LABS].
            </p>
            <p className="x-x">
              1.3. H2B LABS udostępnia Użytkownikowi narzędzie do zautomatyzowanej
              analizy potencjału fotowoltaicznego gruntów i dachów wskazanych
              przez Użytkownika za pośrednictwem Aplikacji.
            </p>
            <p className="x-x">
              1.4. Regulamin stanowi prawnie wiążącą umowę pomiędzy
              Użytkownikiem, zakładającym Konto, a H2B LABS. H2B LABS może, według
              własnego uznania, zmienić treść niniejszego Regulaminu w dowolnym
              momencie, np. w przypadku zmian w obowiązujących przepisach prawa,
              zmian w usługach i funkcjach oferowanych przez H2B LABS lub
              wprowadzenia do oferty nowych usług. Każda taka zmiana zaczyna
              obowiązywać po czternastu (14) dniach roboczych od daty
              opublikowania zaktualizowanej treści Regulaminu. Jeśli Użytkownik
              nie zgadza się z wprowadzonymi zmianami, ma prawo usunąć Konto.
              Zalogowanie się do Konta po zmianie Regulaminu uważane jest za
              jednoznaczne z akceptacją treści Regulaminu.
            </p>
            <p className="x-x">
              1.5. Utworzenie konta oznacza, że H2B LABS będzie przechowywać i
              przetwarzać dane osobowe osoby administrującej Kontem w imieniu
              Użytkownika. Zasady przetwarzania danych osobowych dostępne są w{" "}
              <Link to={{ pathname: "/privacy" }}> Polityce Prywatności.</Link>
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">2. Definicje</p>
            <p className="x-x">
              2.1. W niniejszym Regulaminie, następujące pojęcia mają znaczenie
              nadane im poniżej:
            </p>
            <p className="x-x-x">
              a. <span classname="bold"> Aplikacja</span> – aplikacja webowa H2B
              LABS, dostępna na Stronie internetowej za pośrednictwem której H2B
              LABS świadczy Usługi;
            </p>
            <p className="x-x-x">
              b. <span classname="bold"> Usługi</span> — wszelkie usługi
              świadczone drogą elektroniczną przez H2B LABS na rzecz Użytkowników
              za pośrednictwem Aplikacji;
            </p>
            <p className="x-x-x">
              c. <span classname="bold">Użytkownik</span> — osoba, która przy
              pomocy Strony korzysta z Usługi świadczonej drogą elektroniczną
              przez H2B LABS w ramach prowadzonej przez siebie działalności
              gospodarczej;
            </p>
            <p className="x-x-x">
              d. <span classname="bold">Formularz rejestracyjny</span> —
              formularz wypełniany przez Użytkownika przy rejestracji na
              Stronie;
            </p>
            <p className="x-x-x">
              e. <span classname="bold">Konto</span> -oznacza indywidualny zbiór
              zasobów i uprawnień dla każdego Użytkownika, uruchomiony na jego
              rzecz przez H2B LABS, po dokonaniu rejestracji za pośrednictwem
              Formularza rejestracyjnego;
            </p>
            <p className="x-x-x">
              f. <span classname="bold">Konto zewnętrzne Użytkownika</span> -
              konto Użytkownika z Serwisu Zewnętrznego (np. Konto Facebook,
              Konto Google), którego dane dostępowe (w postaci loginu i hasła)
              służą do założenia Konta.
            </p>
            <p className="x-x-x">
              g. <span classname="bold">Dane Konta zewnętrznego</span> - na
              potrzeby niniejszego Regulaminu oznaczają te dane Użytkownika z
              Konta Zewnętrznego Użytkownika, które Użytkownik zgadza się
              przenieść (skopiować) do Konta, w szczególności e-mail, imię,
              nazwisko. Hasło Konta Zewnętrznego Użytkownika nie jest zapisywane
              w Koncie. Logowanie do Konta Zewnętrznego odbywa się zawsze w
              okienku logowania odpowiedniego Serwisu zewnętrznego.
            </p>
            <p className="x-x-x">
              h. <span classname="bold">Serwis zewnętrzny</span> - platforma
              internetowa składająca się ze stron i innych narzędzi
              internetowych udostępnianych za pośrednictwem sieci Internet
              prowadzona przez usługodawcę innego niż Administrator (np.
              Facebook, Google) umożliwiająca dostęp do Usługi, korzystając z
              tożsamości Użytkownika zapisanej w Danych Konta Zewnętrznego oraz
              uwierzytelnienie i uzupełnienie Konta Użytkownika tymi danymi w
              Usłudze
            </p>
            <p className="x-x-x">
              i. <span classname="bold">Hasło</span> - oznacza ciąg znaków
              literowych, cyfrowych lub innych wybranych przez Użytkownika
              podczas Rejestracji na Stronie lub nadanych przez Usługodawcę,
              wykorzystywany w celu zabezpieczenia dostępu do Konta;
            </p>
            <p className="x-x-x">
              j. <span classname="bold">Dane</span> – informacje na temat
              działki lub dachu umieszczane przez Użytkowników w aplikacji,
            </p>
            <p className="x-x-x">
              k. <span classname="bold">Raport</span> – informacja generowana na
              podstawie Danych Użytkownika dotycząca potencjalnej instalacji
              fotowoltaicznej.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">3. Rejestracja</p>
            <p className="x-x">
              3.1. Użytkownicy oświadczają, że korzystają z Aplikacji wyłącznie
              komercyjnie, a nie jako konsumenci, ani przedsiębiorcy w
              rozumieniu art. 385(5) kodeksu cywilnego.
            </p>
            <p className="x-x">3.2. Rejestracja Konta możliwa jest poprzez:</p>
            <p className="x-x-x">
              a. prawidłowe wypełnienie formularza rejestracyjnego, jeżeli taki
              formularz jest dostępny na Stronie internetowej,
            </p>
            <p className="x-x-x">
              b. używając Danych Konta Zewnętrznego pobranych z Konta
              Zewnętrznego Użytkownika
            </p>
            <p className="x-x">
              3.3. Rejestrując Konto Użytkownik oświadcza, że osoba dokonująca
              rejestracji jest upoważniona do zaciągania zobowiązań w imieniu
              Użytkownika, a także że jest uprawniona do zarejestrowania Konta.
            </p>

            <p className="x-x">
              3.4. Rejestracja z użyciem Danych Konta Zewnętrznego pobranych z
              Konta Zewnętrznego Użytkownika wymaga uprzedniego potwierdzenia,
              że dane, które Użytkownik wcześniej zapisał w Serwisie
              Zewnętrznym, mogą być przekazane H2B LABS.
            </p>
            <p className="x-x">
              3.5. Po dokonaniu rejestracji Danymi Konta Zewnętrznego, przy
              kolejnych logowaniach do Konta Użytkownik posługuje się loginem i
              hasłem z Konta Zewnętrznego Użytkownika lub loginem i hasłem Konta
              w Serwisie Internetowym.
            </p>
            <p className="x-x">
              3.6. Użytkownik może w ustawieniach Konta zrezygnować z połączenia
              Konta z Kontem Zewnętrznym Użytkownika. W takim wypadku konieczne
              będzie ustanowienie nowego hasła do Konta.
            </p>
            <p className="x-x">
              3.7. Usunięcie Konta Zewnętrznego Użytkownika połączonego z Kontem
              w Serwisie Zewnętrznym nie powoduje usunięcia Konta, ale w
              sytuacji braku ustanowienia osobnego hasła do Konta spowoduje
              utratę dostępu do niego.
            </p>

            <p className="x-x">
              3.8. Akceptując treść Regulaminu Użytkownik wyraża zgodę na
              stosowanie faktur elektronicznych w rozumieniu art. 106n Ustawy o
              podatku od towarów i usług.
            </p>
            <p className="x-x">
              3.9. Użytkownik wysyłając Formularz rejestracyjny lub
              uwierzytelniając Konto za pośrednictwem Konta Zewnętrznego
              Użytkownika oświadcza, że:
            </p>
            <p className="x-x-x">
              a. podane w nim dane są kompletne i zgodne ze stanem faktycznym;
            </p>
            <p className="x-x-x">
              b. zapoznał się z Regulaminem i{" "}
              <Link to={{ pathname: "/privacy" }}> Polityce Prywatności.</Link>{" "}
              i zobowiązuje się ich przestrzegać.
            </p>
            <p className="x-x">
              3.10. H2B LABS po otrzymaniu prawidłowo wypełnionego Formularza
              rejestracyjnego utworzy dla Użytkownika w ramach Aplikacji
              unikalne Konto dla wybranego przez niego adresu email.
            </p>
            <p className="x-x">
              3.11. Użytkownik podając adres email w ramach Aplikacji oświadcza,
              iż adres email nie narusza praw osób trzecich. Użytkownik ponosi
              wyłączną odpowiedzialność za wybór adresu email, a wszelkie spory
              na tym tle rozstrzygane są bez udziału Administratora.
            </p>
            <p className="x-x">
              3.12. Użytkownik uzyskuje dostęp do Konta za pomocą unikalnego
              identyfikatora jakim jest adres email i hasło lub za pośrednictwem
              Danych Konta Zewnętrznego. Użytkownik zobowiązany jest nie
              ujawniać jakiejkolwiek osobie trzeciej wraz z adresem email hasła
              i ponosi wyłączną odpowiedzialność za szkody wyrządzone na skutek
              ich ujawnienia.
            </p>
            <p className="x-x">
              3.13. Dla jednego Użytkownika tworzone jest jedno Konto, bez
              względu na to, w jaki sposób korzysta on z Aplikacji.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">4. Zasady korzystania z Aplikacji </p>
            <p className="x-x">
              4.1. Wymagania techniczne niezbędne do prawidłowego funkcjonowania
              Aplikacji:
            </p>
            <p className="x-x-x">a. połączenie z siecią Internet;</p>
            <p className="x-x-x">b. przeglądarka internetowa;</p>
            <p className="x-x-x">b. posiadanie i podanie adresu e-mail.</p>
            <p className="x-x">
              4.2. H2B LABS nie odpowiada wobec Użytkowników za przerwy w dostępie
              do Aplikacji spowodowane awariami łącz telekomunikacyjnych.
            </p>
            <p className="x-x">
              4.3. Wszelkie materiały, w tym elementy grafiki, układ i
              kompozycja tych elementów (tzw. layout), znaki towarowe oraz inne
              informacje, w tym bazy danych dostępne w Aplikacji stanowią
              własność H2B LABS.
            </p>
            <p className="x-x">
              4.4. Pobieranie lub wykorzystywanie w jakimkolwiek zakresie
              dostępnych w ramach Aplikacji materiałów, wymaga każdorazowo zgody
              H2B LABS, nie może naruszać postanowień Regulaminu oraz powszechnie
              obowiązującego prawa.
            </p>
            <p className="x-x">
              4.5. Zabronione jest jakiekolwiek agregowanie i przetwarzanie
              danych oraz innych informacji dostępnych w Aplikacji w celu ich
              dalszego udostępniania osobom trzecim w ramach innych platform
              internetowych jak i poza Internetem.
            </p>
            <p className="x-x">
              4.6. Zabronione jest również wykorzystywanie oznaczeń Aplikacji, w
              tym charakterystycznych elementów grafiki, w ramach świadczenia
              własnych usług.
            </p>
            <p className="x-x">
              4.7. Żadna ze Stron nie ponosi odpowiedzialności za naruszenia
              bezpieczeństwa spowodowane niepowodzeniem zabezpieczenia przez
              drugą Stronę sieci lub jakichkolwiek informacji dostępowych, w tym
              danych logowania i haseł.
            </p>
            <p className="x-x">
              4.8. Użytkownik zapewni, że nie wprowadzi żadnego złośliwego
              oprogramowania do Aplikacji ani takiego, które w przypadku
              zaistnienia określonego zdarzenia, upływu czasu lub podjęcia lub
              zaniechania jakiegokolwiek działania, spowoduje zniszczenie
              Aplikacji, uszkodzenie lub sprawi, że Usługi przestaną działać.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">5. Dane Użytkowników i generowanie Raportu</p>
            <p className="x-x">
              5.1. Użytkownik za pośrednictwem Konta może złożyć zamówienie na
              Raport.
            </p>
            <p className="x-x">
              5.2. Dane umieszczane przez Użytkowników w Aplikacji i
              przekazywane przy jej pomocy informacje, nie powinny być sprzeczne
              z prawem, normami etycznymi oraz dobrymi obyczajami obowiązującymi
              w Polsce.
            </p>
            <p className="x-x">
              5.3. Wygenerowanie Raportu jest odpłatną usługą świadczoną przez
              H2B LABS.
            </p>
            <p className="x-x">
              5.4. Aktualna Cena i dostępne metody płatności dostępne są na
              Stronie internetowej pod adresem
              <Link to={{ pathname: "/pricing" }}> Sunmetric - Cennik.</Link>
            </p>
            <p className="x-x">
              5.5. Wygenerowanie Raportu jest odpłatną usługą świadczoną przez
              H2B LABS.
            </p>
            <p className="x-x">
              5.6. Raport generowany jest na podstawie Danych Użytkownika i
              analizuje potencjał fotowoltaiczny gruntów Użytkownika.
            </p>
            <p className="x-x">
              5.7. Po złożeniu zamówienia na Raport przez Użytkownika H2B LABS
              niezwłocznie potwierdzi jego otrzymanie. Potwierdzenie otrzymania
              zamówienia na Raport następuje poprzez przesłanie przez H2B LABS
              Użytkownikowi wiadomości e-mail na adres wskazany przez
              Użytkownika.
            </p>
            <p className="x-x">
              5.8. Wygenerowanie Raportu przez Użytkownika jest warunkowane
              otrzymaniem wymagalnej płatności przez H2B LABS na zasadach
              określonych w pkt. 5.4. powyżej. W przypadku niedokonania
              płatności przez Użytkownika zamówienie na Raport jest anulowane.
            </p>
            <p className="x-x">
              5.9. H2B LABS dokonuje zwrotu płatności przy użyciu takiego samego
              sposobu płatności, jakiego użył Użytkownik (na ten sam rachunek
              bankowy, z którego dokonana została płatność), chyba że Użytkownik
              wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się
              dla niego z żadnymi kosztami.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">6. Licencja</p>
            <p className="x-x">
              6.1. H2B LABS zachowuje wszelkie prawa, tytuły i udziały w:
            </p>
            <p className="x-x-x">
              a. Usługach świadczonych za pośrednictwem Strony (i wszelkich
              zmianach, modyfikacjach i dziełach pochodnych),
            </p>
            <p className="x-x-x">
              b. wszelkich materiałach dostarczonych przez H2B LABS Użytkownikowi
              w odniesieniu do Strony lub Aplikacji, zastrzeżonych danych lub
              innych zastrzeżonych informacjach opracowanych lub dostarczonych
              przez H2B LABS lub jego dostawców, takich jak tekst, grafika,
            </p>
            <p className="x-x-x">
              c. wszelkich patentach, prawach autorskich, znakach towarowych,
              tajemnicach handlowych i wszelkich innych formach praw własności
              intelektualnej uznanych w dowolnej jurysdykcji, w tym we wnioskach
              i rejestracjach wszelkich wyżej wymienionych, (łącznie zwanych
              „Własnością H2B LABS”).
            </p>
            <p className="x-x">
              6.2. Regulamin ten nie stanowi umowy sprzedaży, a tytuł, patent,
              prawo autorskie, znak handlowy, tajemnica handlowa, własność
              intelektualna lub inne prawa własności do dowolnej własności H2B
              LABS nie są przekazywane Użytkownikowi.
            </p>
            <p className="x-x">
              6.3. H2B LABS niniejszym udziela Użytkownikowi ograniczonej,
              niewyłącznej, nieprzenoszalnej licencji na użytkowanie Własności
              H2B LABS opracowanej wyłącznie do celów korzystania z Usług.
            </p>
            <p className="x-x">
              6.4. Użytkownik może drukować, kopiować i wewnętrznie
              rozpowszechniać dokumentację programu, instrukcje użytkownika,
              podręczniki techniczne produktów i inne informacje (dostarczone
              przez H2B LABS lub udostępnione przez H2B LABS online) wyłącznie do
              wewnętrznych celów biznesowych, pod warunkiem, że Użytkownik
              skopiuje wszystkie informacje o prawach autorskich i innych
              zastrzeżeniach praw majątkowych zawarte w oryginalnym egzemplarzu
              Dokumentacji.
            </p>
            <p className="x-x">6.5. Użytkownik nie będzie:</p>
            <p className="x-x-x">
              a. udzielać podlicencji, dzierżawić, wynajmować, wypożyczać ani w
              inny sposób przekazywać osobom trzecim prawa dostępu do Usług
              dostępnych na Stronie;
            </p>
            <p className="x-x-x">
              b. korzystać ze Strony ani uzyskiwać do niej dostępu w celu
              budowania konkurencyjnego produktu;
            </p>
            <p className="x-x-x">
              c. kopiować, tworzyć ani modyfikować jakichkolwiek dzieł
              pochodnych Strony (lub jakiegokolwiek komponentu, części, cechy,
              funkcji, interfejsu użytkownika lub ich grafiki), za wyjątkiem
              uprzedniej udokumentowanej zgody H2B LABS lub w zakresie, w jakim
              takie ograniczenie jest zabronione obowiązującym prawem;
            </p>
            <p className="x-x-x">
              d. demontować, odtwarzać kodu źródłowego ani w inny sposób
              próbować uzyskać lub poznać kod źródłowy, z którego komponent
              Strony jest kompilowany lub interpretowany;
            </p>
            <p className="x-x-x">
              e. przeprowadzać lub ujawniać jakichkolwiek testów porównawczych
              lub testów wydajności Strony bez uprzedniej pisemnej zgody H2B LABS;
            </p>
            <p className="x-x-x">
              f. wykonywać ani ujawniać jakichkolwiek testów bezpieczeństwa
              Strony lub związanej z nimi infrastruktury bez uprzedniej pisemnej
              zgody H2B LABS, w tym między innymi wykrywania sieci, identyfikacji
              portu i usługi, skanowania podatności na zagrożenia, łamania
              haseł, zdalnego dostępu lub testowania penetracyjnego;
            </p>
            <p className="x-x-x">
              g. usuwać ani modyfikować jakichkolwiek oznaczeń programów lub
              jakichkolwiek informacji o prawach własności H2B LABS lub jej
              licencjodawców;
            </p>
            <p className="x-x-x">
              h. korzystać ze Strony z naruszeniem obowiązujących przepisów;
            </p>
            <p className="x-x-x">
              i. wysyłać ani przechowywać materiałów niezgodnych z prawem,
              nieprzyzwoitych, zawierających groźby lub w inny sposób
              niezgodnych z prawem lub zawierających treści obraźliwe, w tym
              materiałów naruszających prawa do prywatności;
            </p>
            <p className="x-x-x">
              j. zakłócać działania Strony lub zawartych w niej danych.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">7. Zawieszenie lub usunięcie Konta</p>
            <p className="x-x">
              7.1. Konto może zostać usunięte przez Użytkownika w każdym czasie,
              poprzez zgłoszenie takiego Konta do usunięcia za pomocą
              funkcjonalności Systemu „Usuń Konto”. Usunięcie Konta jest
              równoznaczne z wypowiedzeniem umowy o świadczenie usług z H2B LABS z
              wyłączeniem opłaconego zamówienia na Raport.
            </p>
            <p className="x-x">
              7.2. H2B LABS może według własnego uznania, zamknąć lub zawiesić
              Konto, wysyłając stosowne powiadomienie na zarejestrowany adres
              e-mail, jeśli istnieją powody by sądzić, że Użytkownik korzysta z
              Konta z naruszeniem Regulaminu lub jakiegokolwiek innego
              wykorzystania Konta, które jest nie do zaakceptowania przez H2B LABS
              lub jeżeli Użytkownik nie wykazał aktywności na Koncie od ponad 4
              lat.
            </p>
            <p className="x-x">
              7.3. Po zamknięciu Konta wszelkie prawa i obowiązki wynikające z
              niniejszego Regulaminu wygasają automatycznie, z wyjątkiem
              zobowiązań płatniczych na rzecz H2B LABS oraz punktów 6,8,9 i 10
              Regulaminu.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">8. Reklamacje</p>
            <p className="x-x">
              8.1. W przypadku niewykonania lub nienależytego wykonania Usług
              przez H2B LABS lub nierealizowania ich zgodnie z Regulaminem,
              Użytkownik ma prawo złożenia reklamacji bezpośrednio do H2B LABS.
              Strony wyłączają przepisy dotyczące rękojmi wynikające z kodeksu
              cywilnego, z zastrzeżeniem poniższych postanowień Regulaminu.
            </p>
            <p className="x-x">
              8.2. Reklamację należy zgłosić najpóźniej w terminie tygodnia od
              dnia podjęcia informacji o jej przyczynach.
            </p>
            <p className="x-x">
              8.3. Wszelkie reklamacje dotyczące Usług należy zgłaszać tylko
              drogą elektroniczną na adres e-mail: support@sunmetric.pl z
              podaniem dokładnego opisu reklamacji i przyczyn jej zgłoszenia
              oraz podaniem danych identyfikujących Użytkownika wnoszącego
              reklamację, z zastrzeżeniem, że:
            </p>
            <p className="x-x-x">
              a. dane identyfikacyjne Użytkownika powinny obejmować przynajmniej
              imię, nazwisko, dane kontaktowe – adres e-mail;
            </p>
            <p className="x-x-x">
              b. opis reklamacji i przyczyn jej zgłoszenia powinien szczegółowo
              uwzględniać opis ewentualnej wady lub ujawnionego błędu oraz ich
              skutki.
            </p>
            <p className="x-x">
              8.4. Przed zgłoszeniem Reklamacji Użytkownik zobowiązany jest
              zweryfikować, że powód niewykonania lub nienależytego wykonania
              Usługi nie wynika z przyczyn, za które odpowiedzialność ponosi
              Użytkownik.
            </p>
            <p className="x-x">
              8.5. Za dzień zgłoszenia reklamacji za pośrednictwem poczty
              elektronicznej uznaje się dzień pojawienia się zgłoszenia w
              serwerze odbiorczym H2B LABS.
            </p>
            <p className="x-x">
              8.6. Niepodanie lub nieprecyzyjne podanie przez Użytkownika ww.
              informacji uniemożliwia rozpatrzenie Reklamacji.
            </p>
            <p className="x-x">
              8.7. Z zakresu reklamacji wyłączone są wady, usterki i
              nieprawidłowości niezwiązane bezpośrednio z korzystaniem z Usług
              lub wynikające z:
            </p>
            <p className="x-x-x">a. błędów lub pomyłek Użytkownika,</p>
            <p className="x-x-x">
              b. nieprawidłowego funkcjonowania przeglądarki internetowej lub
              łączy telekomunikacyjnych po stronie Użytkownika,
            </p>
            <p className="x-x-x">
              c. działalności podmiotów trzecich, które nie uczestniczą z
              inicjatywy H2B LABS w świadczeniu Usług.
            </p>
            <p className="x-x">
              8.8. Jeżeli podane w reklamacji informacje wymagają uzupełnienia,
              przed rozpatrzeniem reklamacji przedstawiciel H2B LABS zwraca się do
              Użytkownika składającego reklamację o ich uzupełnienie.
            </p>
            <p className="x-x">
              8.9. Odpowiedź na reklamację zawierająca sposób jej rozpatrzenia,
              zostanie wysłana wyłącznie na adres poczty elektronicznej podany
              przez Użytkownika. W szczególnie uzasadnionych przypadkach, w tym
              na wyraźną prośbę Użytkownika, Administrator wysyła odpowiedź na
              reklamację na inny adres poczty elektronicznej, wskazany przez
              Użytkownika lub w formie pisemnej na wskazany przez Użytkownika
              adres do korespondencji.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">9. Disclaimer</p>
            <p className="x-x">
              9.1. H2B LABS nie ponosi odpowiedzialności za jakiekolwiek szkody
              powstałe na skutek zaprzestania świadczenia Usług, w przypadku,
              gdy stanowi to konsekwencję braku możliwość skutecznego wykonania
              usług z winy Użytkownika, np. brak możliwości sfinalizowania
              zamówienia z powodu przepełnionej skrzynki e-mail Użytkownika. H2B
              LABS nie jest również odpowiedzialny za szkody powstałe na skutek
              zaprzestania świadczenia usług i usunięcia konta Użytkownika
              naruszającego Regulamin.
            </p>
            <p className="x-x">
              9.2. H2B LABS nie odpowiada za działania i decyzje podejmowane przez
              Użytkowników na podstawie informacji uzyskanych za pośrednictwem
              Raportu.
            </p>
            <p className="x-x">
              9.3. H2B LABS nie ponosi ponadto odpowiedzialności za:
            </p>
            <p className="x-x-x">
              a. treść lub aktualność publikowanych Danych Użytkownika.
            </p>
            <p className="x-x-x">
              b. jakiekolwiek szkody wyrządzone osobom trzecim, powstałe w
              wyniku korzystania przez Użytkowników z Usług w sposób sprzeczny z
              Regulaminem lub przepisami prawa;
            </p>
            <p className="x-x-x">
              c. utratę przez Użytkownika Danych spowodowanych działaniem
              czynników zewnętrznych (np. awaria oprogramowania) lub też innymi
              okolicznościami niezależnymi od H2B LABS (działanie osób trzecich);
            </p>
            <p className="x-x-x">
              d. szkody wynikłe na skutek braku ciągłości dostarczania Usług;
            </p>
            <p className="x-x-x">
              e. podania przez Użytkowników nieprawdziwych lub niepełnych
              informacji przy rejestracji konta lub korzystaniu z Usług;
            </p>
            <p className="x-x-x">
              f. nieprzestrzegania przez Użytkownika warunków Regulaminu.
            </p>
          </div>

          <img src={paragraph} alt="p" />
          <div className="privacy-paragraph">
            <p className="title">10. Postanowienia końcowe</p>
            <p className="x-x">
              10.1. Konto jest niezbywalne, a dostęp do niego i prawo do jego
              użytkowania przysługują tylko Użytkownikowi, bezpośrednio i
              wyłącznie do własnych celów biznesowych.
            </p>
            <p className="x-x">
              10.2. Użytkownik przyjmuje do wiadomości, że Konto nie może w
              żadnym wypadku być wykorzystywane do odsprzedaży usług H2B LABS
              stronom trzecim.
            </p>
            <p className="x-x">
              10.3. H2B LABS zastrzega sobie prawo do zmiany Regulaminu. O treści
              proponowanych zmian Użytkownicy zostaną poinformowani za
              pośrednictwem poczty elektronicznej.
            </p>
            <p className="x-x">
              10.4. Odrębna pisemna Umowa zawarta pomiędzy H2B LABS a konkretnym
              Użytkownikiem może modyfikować postawienia Regulaminu w relacjach
              pomiędzy H2B LABS a konkretnym Użytkownikiem (tzw. umowa
              enterprise). Postanowienia odrębnej Umowy nie wpływają na warunki
              obowiązywania Regulaminu w stosunku do pozostałych użytkowników.
            </p>
            <p className="x-x">
              10.5. Sądem właściwym do rozstrzygania sporów związanych z
              realizacją postanowień Regulaminu jest sąd właściwy dla
              każdorazowej siedziby Administratora
            </p>
            <p className="x-x">
              10.6. W sprawach nieuregulowanych w Regulaminie zastosowanie mają
              przepisy ustawy o świadczeniu usług drogą elektroniczną, ustawy o
              ochronie danych osobowych, kodeksu cywilnego i inne bezwzględnie
              obowiązujące przepisy prawa polskiego.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
