import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appLang: localStorage.getItem("appLang") || "pl",
};

export const appLangSlice = createSlice({
  name: "appLang",
  initialState,
  reducers: {
    changeAppLang(state, action) {
      state.appLang = action.payload.appLang;
    },
  },
});

export const { changeAppLang } = appLangSlice.actions;
