import React from "react";
import logo from "../../../assets/logo.svg";
import logoSmall from "../../../assets/img/logo-sunmetric/logoC.webp";
import { Link } from "react-router-dom";
import "./Logo.scss";

const Logo = ({ small }) => {
  return (
    <Link
      to={{
        pathname: "/",
      }}
      onClick={() => window.scrollTo(0, 0)}
    >
      {small ? (
        <>
          <img className="logo logo-desktop" src={logo} alt="sunmetric logo" />
          <img
            className="logo logo-mobile"
            src={logoSmall}
            alt="sunmetric logo"
          />
        </>
      ) : (
        <img className="logo" src={logo} alt="sunmetric logo" />
      )}
    </Link>
  );
};

export default Logo;
