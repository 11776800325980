import React from "react";
import Title from "../../atoms/Title/Title";
import Frame from "../../atoms/Frame/Frame";
import "./PricingInfo.scss";
import analysis from "../../../assets/icons/analysis.svg";
import kw from "../../../assets/icons/kw.svg";
import Image from "../../atoms/Image/Image";
import { useTranslation } from "react-i18next";

const PricingInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="pricings-info container-full-width">
      <div className="pricing-info-frame-container">
        <Title text={t("pricing.section1.title")} size="large" />
        <div className="frames-container">
          <Frame>
            <div className="pricings-info-frame">
              <h2 className="price">{t("pricing.section1.cardTitle1")}</h2>
              <Image img={analysis} />
              <h3 className="price">139zł/{t("pricing.section1.plot")}</h3>
            </div>
          </Frame>
          <Frame reverse>
            <div className="pricings-info-frame">
              <h2 className="price">{t("pricing.section1.cardTitle2")}</h2>
              <Image img={kw} />
              <h3 className="price">39zł/{t("pricing.section1.plot")}</h3>
            </div>
          </Frame>
        </div>
      </div>
    </div>
  );
};

export default PricingInfo;
