import React from "react";
import Header from "../../organisms/Header/Header";
import WhySunmetric from "../../organisms/WhySunmteric/WhySunmetric";
import Encouragement from "../../organisms/CTA/CTA";
import pricing from "../../../assets/img/pricing-header.svg";
import "./Pricing.scss";
import PricingInfo from "../../organisms/PricingInfo/PricingInfo";
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="pricing">
      <Header
        title1={t("pricing.header1")}
        title2={t("pricing.header2")}
        img={pricing}
      />
      <PricingInfo />
      <WhySunmetric bg={false} />
      <Encouragement />
    </div>
  );
};

export default Pricing;
