import { useState, useEffect } from "react";
import "./ScrollTopButton.scss";
import ArrowIcon from "../../../assets/icons/arrow-up.png";
import { animateScroll } from "react-scroll";
import Image from "../../atoms/Image/Image";

const ScrollTopButton = () => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const updateIsShown = () => {
      setIsShown(window.scrollY > 600);
    };

    updateIsShown();

    window.addEventListener("scroll", updateIsShown);
    return () => {
      window.removeEventListener("scroll", updateIsShown);
    };
  }, [isShown]);

  return (
    <button
      className={`scroll-top-button${isShown ? " is-shown" : ""}`}
      onClick={() => animateScroll.scrollToTop()}
      aria-label="scroll"
    >
      <Image img={ArrowIcon} className="scroll-top-button-arrow" />
    </button>
  );
};

export default ScrollTopButton;
