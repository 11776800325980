import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
	blogPost: { attributes: { title: '', lead: '', text: '', image: {} }, id: null },
	error: false,
	loading: false,
	success: false,
}

export const getBlogPost = createAsyncThunk(
	'blogPost',
	async ({ id }, { rejectWithValue }) => {
		try {
			const response = await axios.get(
        `https://cms.sunmetric.pl/api/posts/${id}/?populate=image`
      );
			return response.data;
		} catch (error) {
			if (error instanceof Error) {
				return rejectWithValue({ error: error.message })
			}
		}
	},
)

export const blogPostSlice = createSlice({
	name: 'blogPost',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBlogPost.pending, (state) => {
			state.loading = true;
			state.success = false;
		});
		builder.addCase(getBlogPost.fulfilled, (state, action) => {
			state.blogPost = action.payload.data;
			state.error = false;
			state.loading = false;
			state.success = true;
		})
		builder.addCase(getBlogPost.rejected, (state) => {
			state.error = true;
			state.loading = false;
		})
	}
});