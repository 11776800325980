import React from "react";
import "./ContactInfo.scss";
import SocialButtons from "../SocialButtons/SocialButtons";
import Title from "../../atoms/Title/Title";
import write from "../../../assets/icons/write.svg";
import call from "../../../assets/icons/call.svg";
import Image from "../../../page/atoms/Image/Image";
import img from "../../../assets/img/contact-info.svg";
import { useTranslation } from "react-i18next";

const ContactInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-info">
      <Title text={t("contact.section2.title")} size="large" />
      <div className="inner">
        <div className="contact-data">
          <div className="contact-data-item">
            <img src={call} alt="" />
            <p>+48 601 192 313</p>
          </div>
          <div className="contact-data-item">
            <img src={write} alt="" />
            <p>office@sunmetric.io</p>
          </div>
          <SocialButtons />
        </div>
        <Image img={img} className="main-img" />
      </div>
    </div>
  );
};

export default ContactInfo;
