import React from 'react';
import Image from '../../atoms/Image/Image';
import StepDescription from '../StepDescription/StepDescription';
import './Step.scss';

const Step = ({ step, reverse }) => {
  const { smallTitle, bigTitle, description, buttonText, buttonColor, img, pathname, linkTo } = step;
  return (
    <div className={`step${reverse ? ' step-reverse' : ''}`}>
      <StepDescription
        smallTitle={smallTitle}
        bigTitle={bigTitle}
        buttonText={buttonText}
        buttonColor={buttonColor}
        pathname={pathname}
        linkTo={linkTo}
      >
        {description}
      </StepDescription>
      <Image img={img} className='step-img' />
    </div>
  );
};

export default Step;
