import { configureStore } from '@reduxjs/toolkit'
import { blogPostSlice } from '../redux/reducers/BlogPost.reducers'
import { blogPostsListSlice } from './reducers/BlogPostsList.reducers'
import { appLangSlice } from "./reducers/AppLang.reducers";

export const store = configureStore({
  reducer: {
    blogPost: blogPostSlice.reducer,
    blogPostsList: blogPostsListSlice.reducer,
    appLang: appLangSlice.reducer,
  },
});