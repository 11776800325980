import React from "react";
import "./Header.scss";
import TitleBig from "../../atoms/TitleBig/TitleBig";
import Image from "../../atoms/Image/Image";

const Header = ({ title1, title2, img }) => {
  return (
    <div className="header-container container">
      <div className="default-header">
        <div className="title">
          <TitleBig title={title1} />
          <TitleBig title={title2} />
        </div>
        <div className="header-img-container">
          <Image img={img} className="step-img" />
        </div>
      </div>
    </div>
  );
};

export default Header;
