import React from "react";
import "./CTA.scss";
import Title from "../../atoms/Title/Title";
import Image from "../../atoms/Image/Image";
import encouragement from "../../../assets/img/encouragment.svg";
import Button from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

const CTA = ({ contactButton = true }) => {
  const { t } = useTranslation();

  return (
    <div className="container encouragement">
      <div className="encouragement-description">
        <Title text={t("encouragement.title")} size="large" />
        <div className="inner">
          <Image img={encouragement} />
          <div className="buttons-wrapper">
            {contactButton && (
              <Button text={t("button.contact")} pathname={"/contact"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
