import React from "react";
import { Link } from "react-router-dom";
import "./FooterLink.scss";

const FooterLink = ({ link, linkTitle }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link
      aria-label={linkTitle}
      className="footer-link"
      to={{
        pathname: link,
      }}
      onClick={() => scrollToTop()}
    >
      {linkTitle}
    </Link>
  );
};

export default FooterLink;
