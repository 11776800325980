import React from 'react';
import './Text.scss';

const Text = ({ text, color, width, textAlign, bold, marginTop, className }) => {
	return (
		<p
			className={`text${className ? ` ${className}` : ''}`}
			style={{ color: color, width: width, textAlign: textAlign, fontWeight: bold, marginTop: marginTop }}
		>
			{text}
		</p>
	);
};

export default Text;
