import React from 'react';
import './Frame.scss';

const Frame = ({ reverse, borderless, children }) => {
	return (
		<div className={`frame${reverse ? ' frame-reverse' : ''} ${borderless ? ' frame-borderless' : ''}`}>
			<div className='frame-inner'>
				<div className='frame-content'>{children}</div>
			</div>
		</div>
	);
};

export default Frame;
