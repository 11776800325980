import React from 'react';
import './Title.scss';

const Title = ({ text, color, size = 'medium' }) => {
	return (
		<h2 style={{ color: color }} className={`title title-${size}`}>
			{text}
		</h2>
	);
};

export default Title;
