import React from 'react';
import Button from '../../atoms/Button/Button';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';
import './StepDescription.scss';

const StepDescription = ({ children, smallTitle, bigTitle, buttonText, buttonColor, pathname, linkTo }) => {
  return (
    <div className="step-description">
      <Text text={smallTitle} className="step-description-subtitle" />
      <Title text={bigTitle} />
      {children}
      {buttonText && (
        <Button
          text={buttonText}
          color={buttonColor}
          pathname={pathname}
          linkTo={linkTo}
        />
      )}
    </div>
  );
};

export default StepDescription;
