import "./Error.scss";
import ErrorImg from "../../../assets/img/errorPage.png";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className="page-not-found-wrapper">
      <h1>{t("common.error")}</h1>
      <div className="img">
        <img src={ErrorImg} alt="404" />
      </div>
    </div>
  );
};

export default NotFoundPage;
