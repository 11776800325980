import "./WhySunmetric.scss";
import Button from "../../atoms/Button/Button";
import React from "react";
import Title from "../../atoms/Title/Title";
import Image from "../../atoms/Image/Image";
import agreement from "../../../assets/img/reasons/agreement.svg";
import hourglass from "../../../assets/img/reasons/hourglass.svg";
import effective from "../../../assets/img/reasons/effective.svg";
import appointment from "../../../assets/img/reasons/appointment.svg";
import money from "../../../assets/img/reasons/money-bag.svg";
import panel from "../../../assets/img/reasons/panel-ground.svg";
import { useTranslation } from "react-i18next";

const WhySunmetric = ({ bg = true, btn = true }) => {
  const { t } = useTranslation();

  const reasons = [
    { id: 1, text: t("whySunmetric.text1"), image: hourglass },
    { id: 2, text: t("whySunmetric.text2"), image: agreement },
    { id: 3, text: t("whySunmetric.text3"), image: panel },
    { id: 4, text: t("whySunmetric.text4"), image: appointment },
    { id: 5, text: t("whySunmetric.text5"), image: money },
    { id: 6, text: t("whySunmetric.text6"), image: effective },
  ];

  const handleDownload = async () => {
    const response = await fetch("/pdf/Raport.pdf");
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Raport Sunmteric.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className={`why-sunmetric ${bg ? "container-full-width" : ""}`}>
      <div className="sunmetric-reasons">
        <Title text={t("whySunmetric.title")} size="large" />
        <div className="inner">
          {reasons.map(({ id, image, text }) => (
            <div className="item-container" key={id}>
              <Image img={image} />
              <Title text={text} size="small" />
            </div>
          ))}
        </div>
        {btn && (
          <Button text={t("button.exampleReport")} onClick={handleDownload} />
        )}
      </div>
    </div>
  );
};

export default WhySunmetric;
