import React from "react";
import Header from "../../organisms/Header/Header";
import mainImg from "../../../assets/img/report-content.svg";
import WhySunmetric from "../../organisms/WhySunmteric/WhySunmetric";
import Encouragement from "../../organisms/CTA/CTA";
import ReportInfo from "../../organisms/ReportInfo/ReportInfo";
import { useTranslation } from "react-i18next";

const ReportContent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header
        title1={t("report.header1")}
        title2={t("report.header2")}
        img={mainImg}
      />
      <ReportInfo />
      <WhySunmetric bg={false} btn={false} />
      <Encouragement />
    </div>
  );
};

export default ReportContent;
