import React from "react";
import Button from "../../atoms/Button/Button";
import "./SocialButtons.scss";
import facebook from "../../../assets/icons/facebook.svg";
import linkedin from "../../../assets/icons/linkedin.svg";

const SocialButtons = () => {
  return (
    <div className="buttons-container">
      <Button
        icon={linkedin}
        text="linkedin"
        color="white"
        height="70px"
        linkTo="https://www.linkedin.com/company/sunmetric/"
        aria-label="button-linkedin"
      />
      <Button
        icon={facebook}
        text="facebook"
        color="white"
        height="70px"
        linkTo="https://www.facebook.com/sunmetricAi/"
        aria-label="button-fb"
      />
    </div>
  );
};

export default SocialButtons;
