import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from '../src/redux/store'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_pl from '../src/translations/pl.json'
import common_en from '../src/translations/en.json'
import ReactGA from 'react-ga4'

const savedAppLang = localStorage.getItem('appLang')

ReactGA.initialize('G-YG1682YD3V')

i18next.init({
  returnNull: false,
  interpolation: { escapeValue: false },
  lng: savedAppLang || 'pl',
  resources: {
    pl: {
      translation: common_pl,
    },
    en: {
      translation: common_en,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
)

reportWebVitals()
