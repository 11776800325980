import React from 'react';
import Button from '../../atoms/Button/Button';
import TitleBig from "../../atoms/TitleBig/TitleBig";
import "./Header.scss";
import { ReactComponent as Panels } from "../../../assets/img/panels.svg";
import panelsImage from "../../../assets/img/panels-alone.png";
import sun from "../../../assets/img/sun.svg";
import circles from "../../../assets/img/circles.svg";

const Header = ({
  title,
  titleYellow,
  subTitle,
  showButton,
  buttonColor,
  buttonText,
  buttonPath,
  showBanner,
  showImage,
}) => {
  return (
    <div className={`header container${showBanner ? " header-banner" : ""}`}>
      <TitleBig title={title} titleYellow={titleYellow} />
      {subTitle && <p className="cursive">{subTitle}</p>}
      {showButton && (
        <Button
          text={buttonText || "Pobierz pierwszy raport bezpłatnie"}
          color={buttonColor}
          pathname={buttonPath}
          linkTo={
            !buttonPath &&
            (buttonPath || "http://dev.sunmetric-dev.pl/register")
          }
        />
      )}
      {showImage && (
        <div className="image-container">
          <img src={sun} alt="" />
          <img src={circles} alt="" className="rotate" />
          <img src={panelsImage} alt="" />
        </div>
      )}
    </div>
  );
};

export default Header;
